<template>
  <div>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12 mt-2"
      nav-class="nav-left"
    >
      <b-tab
        active
        @click.prevent="activeTab = 1"
      >
        <template #title>
          <!-- <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          /> -->
          <b-avatar
            :style="activeTab === 1 ? 'background: #7838ff; color: #fff;' : 'background: #F0F1F7; color: #A7AAB9;'"
            class="mr-50 tab-profile-avatar"
            size="40px"
          >
            <feather-icon
              icon="UserIcon"
              class="mr-0 pr-0"
              size="22"
            />
          </b-avatar>
          <span
            class="font-weight-bold  tab-profile-text"
            :style="activeTab === 1 ? 'color: #181E38' : 'color: #656B85;'"
          >Personal Information</span>
        </template>

        <account-setting-general />
      </b-tab>

      <b-tab @click.prevent="activeTab = 2">
        <template #title>
          <!-- <feather-icon
            icon="CreditCardIcon"
            size="18"
            class="mr-50"
          /> -->
          <!-- <div class="tab-profile"> -->
          <b-avatar
            :style="activeTab === 2 ? 'background: #7838ff; color: #fff;' : 'background: #F0F1F7; color: #A7AAB9;'"
            class="mr-50 tab-profile-avatar"
            size="40px"
          >
            <feather-icon
              icon="CreditCardIcon"
              class="mr-0 pr-0"
              size="22"
            />
          </b-avatar>
          <span
            class="font-weight-bold  tab-profile-text"
            :style="activeTab === 2 ? 'color: #181E38' : 'color: #656B85;'"
          >Billing information</span>
          <!-- </div> -->
        </template>

        <!-- <taxonomy-setting /> -->
        <account-payment-method />
      </b-tab>

      <b-tab @click.prevent="activeTab = 3">
        <template #title>
          <!-- <feather-icon
            icon="LockIcon"
            size="18"
            class="mr-50"
          /> -->
          <b-avatar
            :style="activeTab === 3 ? 'background: #7838ff; color: #fff;' : 'background: #F0F1F7; color: #A7AAB9;'"
            class="mr-50 tab-profile-avatar"
            size="40px"
          >
            <feather-icon
              icon="LockIcon"
              class="mr-0 pr-0"
              size="22"
            />
          </b-avatar>
          <span
            class="font-weight-bold  tab-profile-text"
            :style="activeTab === 3 ? 'color: #181E38' : 'color: #656B85;'"
          >Login & password</span>
        </template>

        <!-- <taxonomy-setting /> -->
        <account-identification />
      </b-tab>

      <b-tab @click="logout">
        <template #title>
          <!-- <feather-icon
            icon="LogOutIcon"
            size="18"
            class="mr-50"
          /> -->
          <b-avatar
            style="background: #F0F1F7; color: #A7AAB9;"
            class="mr-50 tab-profile-avatar"
            size="40px"
          >
            <feather-icon
              icon="LogOutIcon"
              class="mr-0 pr-0"
              size="22"
            />
          </b-avatar>
          <span
            class="font-weight-bold  tab-profile-text"
            style="color: #656B85;"
          >Log out</span>
        </template>

        <!-- <taxonomy-setting /> -->
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab, BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
  BNavItem, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountPaymentMethod from './AccountPaymentMethod.vue'
import AccountIdentification from './AccountIdentification.vue'
// import TaxonomySetting from './TaxonomySetting.vue'
import useJwt from '@/auth/jwt/useJwt'
import { db, auth } from '@/firebase'

export default {
  components: {
    BTabs,
    BTab,
    BFormFile,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BAvatar,
    BNavItem,

    AccountSettingGeneral,
    AccountPaymentMethod,
    AccountIdentification,
    // TaxonomySetting,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      usuarioData: {},
      activeTab: 1,
    }
  },
  methods: {
    ...mapActions(['cerrarSesion']),
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Reset ability
      // this.$ability.update(initialAbility)

      // Redirect to login page
      // this.$router.push({ name: 'auth-login' })
      this.cerrarSesion()
    },
  },
  created() {
    const user = auth.currentUser

    const ref = db.collection('Users').doc(user.uid)
    ref.onSnapshot(doc => {
      this.usuarioData = {}
      this.usuarioData = {
        id: doc.data().uid,
        fullName: doc.data().nombre,
        email: doc.data().email,
        role: doc.data().role,
        avatar: doc.data().foto,
        contact: doc.data().contact,
      }
    })
  },
}
</script>

<style>
.nav-link.active {
  background: #F0F1F3 !important;
  border-color: #F0F1F3 !important;
  box-shadow: none !important;
  color: #181E38 !important;
}
/* .tab-profile:hover {
  color: red !important;
  background: red;
} */
/* li.nav-item {
  a:hover > * {
    color: red !important;
  }
} */
</style>

<style lang="scss" scoped>
li.nav-item {
  // li.tab-profile{
    a:hover > .tab-profile-text {
      color: #181E38 !important;
    }
    a:hover > .tab-profile-avatar {
      background: #7838ff !important;
      color: #fff !important;
    }
  // }
}
</style>
